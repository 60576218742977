
import apiFetch from "@/utils/request";
import { Vue } from "vue-class-component";

class Login extends Vue {
  username: string = "";
  password: string = "";
  error: string = "";
  async login() {
    try {
      const payload: { username: string; password: string } = {
        username: this.username,
        password: this.password,
      };
      await apiFetch.auth.login(payload);
      this.$store.state.isLoggedIn = true;
      this.$router.push({ path: "/" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
}
export default Login;
