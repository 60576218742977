<template>
  <div class="form">
    <!-- cloud storage form component -->
    <div id="cloud-storage-form" class="form">
      <h2>Login Page</h2>
      <div v-if="error" class="err-msg">{{ error }}</div>
      <div class="form-content">
        <div class="form-input">
          <label for="username"> Username: </label>
          <input id="username" v-model="username" name="username" type="text" />
        </div>
        <div class="form-input">
          <label for="password"> Password: </label>
          <input
            id="password"
            v-model="password"
            name="password"
            type="password"
          />
        </div>
        <button class="login-btn" @click="login">Login</button>
      </div>
    </div>
    <!-- cloud storage form component -->
  </div>
</template>

<script lang="ts">
import apiFetch from "@/utils/request";
import { Vue } from "vue-class-component";

class Login extends Vue {
  username: string = "";
  password: string = "";
  error: string = "";
  async login() {
    try {
      const payload: { username: string; password: string } = {
        username: this.username,
        password: this.password,
      };
      await apiFetch.auth.login(payload);
      this.$store.state.isLoggedIn = true;
      this.$router.push({ path: "/" });
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
}
export default Login;
</script>
